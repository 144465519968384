.App {
  text-align: center;
}
.background {
  width: 100%;
  background-image: linear-gradient(#4b4a5a, #403d4d);
  position: absolute;
  z-index: -1;
  display: flex;
  align-items: flex-end;
  height: 100vh;
}
.backgroundImage {
  background-repeat: no-repeat;
  height: 406px;
  width: 100%;
  bottom: 0;
  background-image: url("./assets/images/img-background-pattern.png");
}
.languageChange {
  position: absolute;
  left: 20px;
  top: 0;
}
.languageMenu {
  position: absolute;
  top: 35px;
  background-color: white;
  border-radius:10px;
  overflow: hidden;
}
.languageMenuItem {
  height: 30px;
  display: flex;
  width: 50px;
  padding: 0 10px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
.languageMenuItem:hover {
  background-color: #c3ebcf;
}
.languageMenuItemSelected:hover {
  background-color: #00586a;
  color: white;
}
#app {
  height: 100%;
  width: 100%;
}
.languageMenuItemSelected {
  background-color: #00586a;
  color: white;
}
.languageSelected {
  background-color: white;
  cursor: pointer;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 30px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
