.mainGrid {
  background-color: rgba(64, 62, 78, 0.71);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 2;
}
.closeIconButton {
  position: absolute;
  left: 16px;
  top: 16px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: rgba(117,204,141, 0.12);
  }
  .closeIcon {
    font-size: 16px;
    color: #1f5869;
  }
}
.cardWrapper{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .card {
    height: 100%;
    width: 100%;
  }
}