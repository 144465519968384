.mainGrid {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  opacity: 0.5;
  transition: background-color,opacity 0.3s ease-in-out;
  &:hover {

    .bodyGrid {
      background-color: rgba(159,220,176, 0.05);
    }
  }
  &.selected {
    opacity: 1;

    .bodyGrid {
      background-color: #5c606a;
    }
  }
  .topGrid {
    font-size: 15px;
    font-weight: bold;
    display: flex;
    padding: 0px 8px;
    margin-bottom: 12px;
  }

  .bodyGrid {
    border-radius: 8px;

    .singleTreatment {
      padding: 8px;
      padding-bottom: 0px;
      .divider {
        height: 1px;
        margin-top: 8px;
        background-image: linear-gradient(to left, #cdf0d8, #9fdcb0);
        opacity: 0.12;
      }

      &:last-child {
        .divider {
          opacity : 0;
        }

      }


    }

  }
}

