.mainGrid {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  text-align:left;
  overflow: auto;

  &.mobile {
    .bodyGrid {
      padding-top:60px;
      .logoGrid {
        display: none;
      }
      width: 90%;
    }
  }

  .bodyGrid {
    width: 60%;
    .logoGrid {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .faqTitle {
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.24;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 16px;
    }
    .supportMailText {
      font-size: 15px;
      color: #cdf0d8;
    }
    .faqDescription {
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.27;
      white-space: pre-wrap;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 36px;
    }
    .additionalInformationWrapper{
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      .title {
        font-size: 17px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.24;
        letter-spacing: normal;
        color: #ffffff;
        margin-right: 4px;
      }
      .description {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
    }
    .mailToWrapper {
      align-items: center;
      justify-content: flex-end;
      display: flex;
      .mailToButton {
        margin-top: 18px;
        margin-bottom: 18px;
        text-decoration: none;
        outline: none;
        border: none;

      }
    }

    .allPersonlDetails {
      width: 100%;
      .personalDetailsWrapper {
        margin-bottom: 4px;

      }
    }
  }



}
