
.upperNavbarItem {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
  background-color: #434352;
  flex-direction: row;
  z-index: 15;
}

.buttonBaseWrapper {
  display: flex;
  align-items: center;
  width: 160px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #f7f7f7;
  float: left;
  cursor: pointer;

}

.buttonText {
  height: 20px;
  font-family: NunitoSans;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

  &.csvButtonText {
    text-decoration: none;
  }
}

.downloadAndBuyWrapper {
  display: flex;
  padding-right: 24px;
}
.excelLoader {
  border: 3px solid transparent; /* transparent */
  border-top: 3px solid white; /* white */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
 }

.downloadEcxl {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.secondaryButtonWrapper {
  padding: 15px 5px 15px 5px;
}

.companyLogoWrapper {
  width: 100%;
  display: flex;
  padding: 15px;
}

.hideElement {
  display: none;
}