.buttonWrapper {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 25px;
  background-image: linear-gradient(to left, #cdf0d8, #9fdcb0);
  padding-right: 15px;
  font-weight: bold;
  font-size: 15px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #1f5869;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #bae5c6;
    background-image: linear-gradient(to left, #bae5c6, #bae5c6);

  }

  &:focus {
    background-image: linear-gradient(to left, #80b28e,#80b28e);

    color: #1f5869;

  }

  &.responsive {
    width: 100%;
  }
}