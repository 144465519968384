

.demoAppCalendar {
  background-color: white;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 24px;

  border-radius: 15px;
  .calenderHeader {
    display: flex;
  }
}

.calenderWrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
  .calenderHeader {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 56px;
    padding-left: 2vw;
    padding-right: 2vw;
    .currentDateTitle {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.33px;
      text-align: center;
      color: #1f5869;
    }
    .dateChangeButton {
      background-color: #1f5869;
      width: 32px;
      height: 32px;
      display: flex;
      color: white;
      outline: none;
      border: none;
      border-radius: 50%;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      justify-content: center;
      &:hover {
        background-color: #1a404b;
      }
      &.disabled {
        opacity: 0.2;
        background-color: #1f5869;
        cursor: default;
      }

      .arrowIconForward {
        transform: rotate(180deg);
      }
      .arrowIcon {

      }
    }
  }
}

.dayWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.eventDay {
  }
  .dayGrid {
    position: relative;
    display: flex;
    justify-content: center;
    height: 48px;
    width: 48px;
    align-items: center;
    box-sizing: border-box;
    border-radius: 50%;

    .day {
      border: none;
      background-color: transparent;
      outline: none;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      justify-content: center;
      border-radius: 50%;
      color: #00586a;

      flex-direction: column;
      .bullet {
        width: 6px;
        height: 6px;
        position: absolute;
        top: 29px;
        left: 50%;
        transform: translate(-50%,0);
        margin-top: 5px;
        border-radius: 50%;
        background-color: #95c8a5;
      }
      &.today::after {
          font-size: 60px;
          position:absolute;
          display: inline;
          background-color: transparent;
          color: green;
          width: 48px;
          height: 48px;
          left:-1px;
          top:  -1px;

          border-radius: 50%;
          content: "";
          border: 1px solid #00586a;
      }

      &.selected {
        color: #00586a !important;
        .bullet {
          display: none;
        }
      }
      &.hasEvent {
        color: #1f5869;
        cursor: pointer;
        font-weight: bold;
      }
      &.notSameMonth {
        opacity: 0.3;
        cursor: default;
        background-color: transparent !important;
      }
      &:hover {
        &.hasEvent {
          color: #1f5869;
          background-color: rgba(117,204,141, 0.1) !important;
        }
        &.notSameMonth {
          background-color: transparent !important;
        }
      }
    }

    &.selected {
      background-color:#95c8a5;
    }
  }
}
button::-moz-focus-inner {
  border: 0;
}
