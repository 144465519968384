
.mainWrapper {
  position: relative;
  height: 100%;
  &.burgerMode {
    position: absolute;
    left: 0;
    z-index:5;
    top: 0;
  }
  &.invisible {
    .navbarWrapper {
      width: 0;

    }
    .burgerWrapper {
      background-color: rgba(161, 219, 177, 0.1);
    }
  }
  .burgerWrapper {
    position: absolute;
    left: 9px;
    top: 10px;
    height: 43px;
    width: 43px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    z-index: 3;
    transition: background-color 0.1s ease-in-out;
    background-color: transparent;
    .icon {
      font-size: 27px;
      color: #b8e4c4;
    }
    &:hover {
      background-color: rgba(161, 219, 177, 0.1);
    }

  }
  .navbarWrapper {
    width: 224px;
    height: 100%;
    display: flex;
    padding-bottom: 8px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #565b66;
    transition: width 0.2s ease-in-out;
    overflow:hidden;
    position: relative;



    .bottomGrid {
      padding: 24px;

      .buttonLink {
        border: none;
        outline: none;
        text-decoration: none;
      }

      .buttonWrapper {

      }
      .needHelpGrid {

        .needHelpText {
          font-size: 15px;
          font-style: normal;
          font-weight: bold;
          font-stretch: normal;
          line-height: 1.4;
          letter-spacing: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #ffffff;
        }

        .supportMailText {
          margin-top: 4px;
          font-size: 10px;
          text-decoration: none;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: normal;
          outline: none;
          border: none;
          letter-spacing: 0.8px;
          color: #b8e4c4;
          border: none !important;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .companyLogoWrapper {
      width: 100%;
      display: flex;
      padding: 15px;
      padding-left: 58px;
      border-bottom: 1px solid  rgba(184,228,196,0.15);

      .companyLogoImage {

      }
    }

    .headerWrapper {
      padding: 24px 47px 24px 16px;
    }

    .navbarItemWrapper {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    .logoutButton {
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      color: #b8e4c4;
      display: flex;
      justify-content: center;
      cursor: pointer;
      margin-top: 12px;
      align-items: center;
      transition: opacity 0.1s ease-in-out;

      &:hover {
        color: #ffffff;
      }
    }

    .versionText {
      font-family: NunitoSans;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9e9da5;

      margin-top: 15px;

    }
  }

}