
 .mainCardWrapper {
      flex-direction: column;
      display: flex;
      width: 100%;
      flex: 1;
      height: 100%;
      position: relative;

      .formGrid {
        display: flex;
        width: 100%;
        flex: 1;

        .fieldsWrapper {
          height: 100%;
          width: 100%;
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;

          .topWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .description {
              display: flex;
              justify-content: flex-start;
              font-size: 15px;
              letter-spacing: 0.4px;
              color: #1f5869;
            }
            .title {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 6px;
              font-size: 28px;
              font-weight: bold;
              color: #1f5869;
            }
          }

          .textFieldWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            overflow:hidden;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            position: relative;
            height: 98px;

            .forgotPassword {
              right: 0;
              bottom: 0px;
              display: flex;
              margin-top: 2px;
              position: absolute;
              justify-content: flex-end;
              font-size: 13px;
              font-weight: bold;
              line-height: 1.62;
              text-align: right;
              color: #1f5869;
              cursor: pointer;
              transition: all 0.2s ease-in-out;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .generalErrorMessage {
        width: 100%;
        font-size: 12px;
        position: absolute;
        bottom: -22px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #d0021b;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      .buttonGrid {
        width: 100%;
        display: flex;
        margin-top: 65px;
        justify-content: center;

        .buttonWrapper {
          width: 75%;
          min-width: 100px;
        }
      }
    }


.notchedOutline {
  font-size: 12px;
  color: #d0021b !important;
}
 .formLabel {
   color: #1f5869 !important;
   font-size: 12px !important;
 }
.baseInput {
  height: 22px !important;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #1f5869 !important;

}
.input {
  width: 100%;
  position: relative;
}
.errorMessage {
  font-size: 12px !important;;
  color: #d0021b !important;
}
.buttonEye {
  position: absolute;
  right: 0;
  top: 19px;
  cursor: pointer;
  font-size: 24px;
  color: #00586a;
}