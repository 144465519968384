.mainGrid {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .bodyGrid {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: column;
    .inputWrapper {
      width: 100%;
    }

    .buttonWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}
.formLabel {
  color: #1f5869 !important;
  font-size: 12px !important;
}
.baseInput {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #1f5869 !important;
}
.input {
  width: 100%;
  position: relative;
}