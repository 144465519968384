.navbarItem {
  height: 40px;
  align-items: center;
outline: none;
  border:none;
  display: flex;
  width: 100%;
  text-decoration: none;
  .navbarItemText {
    margin-left: 25px;
    font-size: 15px;
    transition: color 0.2s ease-in-out;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
  }
  .border {
    width: 0px;
    height: 100%;
    margin-right: 4px;
    transition: all 0.2s ease-in-out;
    background-color: #c3ebcf;
  }
  &:hover {
    .navbarItemText {
      color: #ebfff0;
    }
  }
  &.active {
    .navbarItemText {
      color: #c3ebcf;
    }
    .border {
      margin-right: 0px;
      width: 4px;
    }
  }
}
