.backButton {
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  left: 8px;
  top: 16px;
  outline: none;
  border: none;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: rgba(117,204,141,0.1);
  }
  &:focus {
    background-color: rgba(128,178,142,0.3);
  }
  .icon {
    font-size: 32px;
    color: #00586a;
  }
}
    .mainCardWrapper {
      flex-direction: column;
      display: flex;
      width: 100%;
      flex: 1;
      height: 100%;

      .formGrid {
        display: flex;
        flex: 1;
        flex-direction: column;

        .topWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .description {
            display: flex;
            justify-content: flex-start;
            font-size: 15px;
            letter-spacing: 0.4px;
            color: #1f5869;
          }
          .title {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 6px;
            font-size: 28px;
            font-weight: bold;
            color: #1f5869;
          }
        }

        .fieldsWrapper {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .textFieldWrapper {
            width: 100%;
            margin-top: 5px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            overflow:hidden;
            flex-direction: column;
            flex: 1;

          }
        }
      }
      .buttonGrid {
        width: 100%;
        display: flex;
        justify-content: center;

        .buttonWrapper {
          width: 75%;
          min-width: 100px;
        }
      }
    }


.formLabel {
  color: #1f5869 !important;
  font-size: 12px !important;
}
.input {
  width: 100%;
}
    .baseInput {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #1f5869 !important;
    }