.indicator {
  background-color: #75cc8d !important;
  opacity: 0.5;
  height: 2px;
}
.mainGrid {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  .footerGrid {
    background-image: linear-gradient(to left, rgba(205,240,216, 0.12), rgba(159,220,176, 0.12));
    padding-top: 48px;

    .dateGrid {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1f5869;
      margin-bottom: 6px;
    }

    .dataGrid {
      .dataValue {
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        color: #1f5869;
        margin-right: 6px;
        margin-left: 6px;
      }
    }
    .tabsWrapper {
      display: flex;
      margin-top: 5px;
      padding-left: 28px;
      padding-right: 28px;
    }
  }
  .bodyGrid {
    flex: 1;
    padding: 24px 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;

    .questionWrapper {
      margin-bottom: 20px;
    }
  }

}
.tabRoot {
  min-height: 20px !important;
}

.tabLabel {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #1f5869 !important;
}
.tabs {
  min-height: 40px !important;
  height: 40px !important;
}
.tab {
  color: #1f5869 !important;;

  min-height: 40px !important;
  height: 40px !important;
  text-transform: none !important;
  &.selected {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #1f5869 !important;
  }
}