.mainWrapper {
  height: 100%;
  width: 100%;
}
.coreWrapper {
  height: 100%;
  width: 100%;
  // display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  // display: block;
  display: flex;     /* NEW - Chrome */
  
}

.sideNavWrapper {
  height: 100%;

}

.contentWrapper {
 height: 92vh;
 width: 100%;
 float:right;
 }

.screenWrapper {
    display: flex;
    height: 100vh;
}
.wrapper{
    flex-direction:column;
}

.headerBg {
  width: 100%;
  height: 70px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
  background-color: #434352;
}