.main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  
  .card {
    position: relative;
    width: 455px;
    height: 478px;
    padding: 64px;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
  }

  .companyLogoImage {
    width: 246px;
    height: 48px;
    object-fit: contain;
    align-self: center;
    margin-bottom: 64px;
    transform:translate(0, 0);
  }
}

