.imgClass {
  object-fit: contain;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.buttonBaseWrapper {
  display: flex;
  align-items: center;
  width: 173px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #f7f7f7;
  float: left;
  cursor: pointer;
  background-color: Transparent;
  // justify-content: center;
}
.buttonBaseWrapper:hover {
  background-color: rgba(247, 247, 247, 0.12);
}
.buttonBaseWrapperSmall {
  display: flex;
  align-items: center;
  width: 155px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #f7f7f7;
  float: left;
  cursor: pointer;
  background-color: Transparent;
  // justify-content: center;

}
.buttonBaseWrapperSmall:hover {
  background-color: rgba(247, 247, 247, 0.12);
}

.buttonText {
  height: 20px;
  font-family: NunitoSans;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 12px 10px 6px;
  width: inherit;

  &.csvButtonText {
    text-decoration: none;
  }
}

.spinnerLoader {
  object-fit: contain;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.disableDiv {
  padding-left: 8px;
  pointer-events: none;
}

.disableText {
  opacity: 0.4;
}