.mainGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-height: 60px;


  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  background-image: linear-gradient(to left, rgba(205,240,216,.12),rgba(159,220,176,0.12));
  overflow: hidden;
  flex: 1;
  .divider {
    width: 100%;
    opacity: 0;
    border-radius: 0.5px;
    margin-bottom: 0px;
    height: 0px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #a1dbb1;
    transition: all 0.3s ease-in-out;
  }
  .headerGrid {
    padding: 20px 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .iconButton {
      cursor : pointer;
      .icon {
        font-size: 24px;
        color: #cdf0d8;
      }
    }
    .titleGrid {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: 0.3px;
      color: white;
    }
  }
  .descriptionGrid {
    padding: 20px 24px;
    padding-top: 0px;
    font-size: 15px;
    text-align: left;
    white-space: pre-wrap;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #ffffff;
    width: 100%;
    flex: 1;
    display: none;
  }
  &.mobile {
    max-height: 65px;
    .titleGrid {
      font-size: 13px !important;
    }
    .descriptionGrid {
      .faqDescription {
        font-size: 13px;
      }
    }
  }
  &.mobile.open {
    max-height: 440px !important;
  }
  &.open {
    .divider {
      opacity: 0.12;
      margin-bottom: 12px;
      height: 1px;
    }
    max-height: 330px;
    .titleGrid {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: 0.3px;
      color: #cdf0d8;
    }
    .descriptionGrid {
      display: flex;
    }

  }

}
