.mainGrid {
  display: flex;
  align-items: center;
  justify-content: end;

  .userIconWrapper {
    background-color: rgba(161,219,177, 0.1);
    height: 48px;
    width: 48px;
    margin-right: 8px;
     border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .userIcon {
      font-size: 22px;
      color: #a1dbb1;
    }
  }

  .userDataGrid {
    width: 120px;
    display: flex;
    overflow: hidden;
    justify-content: flex-start;
    flex-direction: column;
    .nameGrid {
      width: 121px;
      text-align:left;
      font-size: 15px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #f7f7f7;
    }
    .emailGrid {
      text-align:left;
      font-size: 10px;
      opacity: 0.84;
      margin-top: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      color: #f7f7f7;
    }
  }
}