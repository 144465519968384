.loader {
  border: 3px solid transparent; /* transparent */
  border-top: 3px solid white; /* white */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

.bigLoader {
  border: 3px solid transparent; /* transparent */
  border-top: 3px solid white; /* white */
  border-radius: 50%;
  width: 135px;
  height: 135px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}