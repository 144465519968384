
    .mainCardWrapper {
      flex-direction: column;
      display: flex;
      width: 100%;
      flex: 1;
      height: 100%;

      .formGrid {
        .topWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .description {
            display: flex;
            color: white;
            justify-content: center;
            font-size: 15px;
            letter-spacing: 0.4px;
            text-align: center;
          }
          .title {
            display: flex;
            justify-content: center;
            margin-bottom: 6px;
            font-size: 28px;
            font-weight: bold;
            color: white;
          }
        }


        .fieldsWrapper {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .textFieldWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            overflow:hidden;
            flex-direction: column;
            justify-content: center;
            flex: 1;
          }
        }
      }
      .imageGrid {
        margin-top: 62px;
        margin-bottom: 62px;
      }
      .buttonGrid {
        width: 100%;
        display: flex;
        justify-content: center;

        .buttonWrapper {
          width: 240px;
          min-width: 100px;
        }
      }
    }


.formLabel {
  font-size: 10px;
}