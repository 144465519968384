// you must include each plugins' css
// paths prefixed with ~ signify node_modules
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

.questionnaireCard {
  height: 80%;
  width: 565px;

}
.demoApp {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  flex: 1;
  justify-content: space-between;
  padding-top: 24px;

  &.mobile {
    flex-direction: column;
    .cardGrid {
      margin-top:10px;
      flex: 3;
      height: auto;
      width: 100%;
      .cardWrapper {
        min-width: 350px;
      }
    }
    .selectedGrid {
      flex: 2;
      position: relative;
      height: auto;
      width: 100%;
    }
  }
  .selectedGrid {
    width: 35%;
    overflow: scroll;
    overflow-x: hidden;    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition: width 0.2s ease-in-out;
    height: 100%;
    color: white;
    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
    background-image: linear-gradient(#4b4a5a, #403d4d);
    .treatmentWrapper {
      width: 100%;
      cursor: pointer;
    }
  }
  .cardGrid {
    padding: 5px;
    position: relative;
    height: 100%;
    padding-bottom: 108px;

    display: flex;
    justify-content: center;
    flex: 1;
    .cardWrapper {
      padding-right: 5px;
      padding-left: 24px;
      min-height: 400px;
      min-width: 400px;
      height: 50vw;
      max-height: 100%;
      display: flex;
      justify-content: center;

    }
  }
}

.demoAppTop {
  margin: 0 0 3em;
}

.calenderWrapper {
  padding: 5px;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.eventPadding {
  height: 250px;
  width: 100%;
}
.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline;

}

.faderWrapper{
  position: relative;
  width: auto;
}
.eventFaderTop {
  position: sticky;
  top: 0;
  height: 150px;
  width: 100%;
  pointer-events: none;
  opacity: 0.7;
  background: linear-gradient(#4a4a59, transparent);
  z-index: 1;
}
.eventFaderBottom {
  position: fixed;
  bottom: 0;
  height: 150px;
  width: 100%;
  pointer-events: none;
  opacity: 0.7;
  background: linear-gradient(transparent, #403d4d);
  z-index: 1;
}
