.mainGrid {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .loaderWrapper {
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%,-50%);

  }
  .iframe {
    outline: none;
    border:none;
    width: 100%;
    height: 100%;
    &.loading {
      display: none;
    }
  }
}
