body {
  margin: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input::-ms-clear {
  display: none;
}
html {
  width: 100%;
  height: 100vh;
}
input::-webkit-credentials-auto-fill-button {
  background-color: transparent;

  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}
.awsappsync {
  height: 100%;
  width: 100%;
}
#root {
  width: 100%;
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  font-family: 'Nunito Sans', sans-serif !important;
  box-sizing: border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
}
