.treatmentData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.selected {
    .episodeGrid {
      opacity: 1;
    }
  }
  .episodeGrid {
    opacity: 0;
    margin-right:8px;

    .symptomsIcon {
      font-size: 24px;
      color: #b8e4c4;
    }

    .symptomsText {
      color: #b8e4c4;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
    }

  }


  .dateGrid {
    display: flex;

  }

  .severityGrid {
    display: flex;

  }

  .intensityGrid {
    display: flex;

  }
}
.treatmentProperty {
  font-size: 13px;
  line-height: 1.6;
  &.bold {
    font-weight: bold;
  }
  &.value {
    margin-left: 8px;
    color: #b8e4c3;
  }
}
