.mainGrid {
  .topGrid {
    font-size: 13px;
    text-align:left;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #1f5869;
  }

  .bottomGrid {
    font-size: 13px;
    text-align:left;
    margin-top: 2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #1f5869;
  }
}