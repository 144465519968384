.fc-event {
    background-color: transparent !important;
    border: none;
}
.fc-event-container {
    display: none;
}
.fc-day-top {
    border-color: solid green 3px;
}
.fc-today {
    background: #ffffa1 !important;
}
.fc-toolbar {
    display: none !important;
}
.fc-row .fc-content-skeleton td, .fc-row .fc-helper-skeleton td {
    border-color: inherit !important;
}
.fc-scroller {
    height: auto !important;
}

.fc-head .fc-widget-header {
    margin-right: 0 !important;
}

.fc-scroller {
    overflow: visible !important;
}
.fc-next-button {
    border-style: double;
    outline: none !important;
    border-radius: 50%;
    height: 26px;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    background-color: #00586a;
    color: #00586a;
}
.fc-next-button:hover{

}
.fc-next-button:focus{

}

.fc-highlight-skeleton {
    z-index: -1 !important;
}
.fc-center {
    height: 50px;
    margin-right: 15px;
    margin-left:15px;
    display: flex;
    align-items: center
}

.fc-toolbar h2 {
    font-size: 18px !important;
}
.fc-day-header {
    color: #00586a;
    height: 25px;
    font-size: 15px;
    font-weight: lighter;
}
.fc-head{
    margin-bottom: 10px;
    border: none;
}
.fc td {
    border-width: 0px !important;

}
.fc th{
    border-width: 0px !important;
}
.fc-center {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.fc-highlight {
    opacity: 1;
    position: relative;
    transition: all 0.2s ease-in-out;
    background: transparent !important;
}
.fc-highlight::after {
    font-size: 60px;
    display: inline;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #b8e4c3;
    color: green;
    height: 48px;
    width: 48px;

    border-radius: 50%;
    content: "";
}
.fc-prev-button {
    border-style: double;
    outline: none !important;
    border-radius: 50%;
    height: 26px;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    background-color: #00586a;
    color: #00586a;
}
.fc-icon{
    font-size: 1.2rem !important;
    color: white;
}
.fc-button-primary {
    border: none !important;
    height: 32px;
    width: 32px;
    outline: none !important;
}
.fc-toolbar h2 {
    margin: 0;
    color: #00586a;
    font-size: 16px;
}
.fc-prev-button:focus{

}
.fc-body {
    border: none;
}

.fc-prev-button:hover{

}
.fc-center {
    display: flex;
    align-items: center;
}
.fc-event-container {
    height: 100%;
    width: 100%;
    padding: 0 !important;
}
.fc-widget-content {
    border: 0px !important;
}
.fc-content-skeleton {
    display: none;
}
.fc-day-number {
    display: none;
}
.fc-row {
    border: 0px !important;

}
.fc-today {
    background-color: transparent !important;
}
.fc-day {
    height: 100%;
    padding: 0 !important;
}